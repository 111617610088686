<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div
      class="card-header border-0 py-2"
      style="background-color: #fff4de !important"
    >
      <div class="card-title justify-space-between width-100">
        <h3 class="card-label" style="font-weight: bold">OVERDUE VISITS</h3>
        <v-btn color="cyan white--text" class="custom-bold-button" v-if="false"
          >New Visit</v-btn
        >
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3 custom-border-top">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table width="100%" class="">
            <thead>
              <tr>
                <th class="simple-table-th">Visit #</th>
                <th class="simple-table-th">Site Location</th>
                <th class="simple-table-th">Customer Details</th>
                <th class="simple-table-th">Project Details</th>
                <th class="simple-table-th">Dates</th>
                <th class="simple-table-th">Actual Date / Time</th>
                <th class="simple-table-th">Status</th>
                <th class="simple-table-th">Assign To</th>
                <th class="simple-table-th">Created Time</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="pageLoading">
                <template v-for="(row, index) in 2">
                  <tr :key="'ref-0-' + index">
                    <td colspan="6">
                      <v-skeleton-loader
                        type="text"
                        class="custom-skeleton table-rows-text width-350px"
                      ></v-skeleton-loader>
                    </td>
                  </tr>
                  <tr :key="'ref-1-' + index">
                    <td width="150">
                      <v-skeleton-loader
                        type="text"
                        class="custom-skeleton table-rows-text"
                      ></v-skeleton-loader>
                    </td>
                    <td width="400">
                      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
                    </td>
                    <td width="300">
                      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
                    </td>
                    <td width="200" class="py-4">
                      <v-skeleton-loader type="text"></v-skeleton-loader>
                      <v-skeleton-loader
                        type="text"
                        class="custom-skeleton table-rows-text"
                      ></v-skeleton-loader>
                    </td>
                    <td>
                      <v-skeleton-loader type="text"></v-skeleton-loader>
                    </td>
                    <td>
                      <v-skeleton-loader type="text"></v-skeleton-loader>
                    </td>
                  </tr>
                </template>
              </template>
              <template v-else-if="rowData && rowData.length">
                <template v-for="(row, index) in rowData">
                  <template v-if="row.header">
                    <tr
                      v-if="!internal"
                      :key="index"
                      :class="{ 'custom-border-top': index > 0 }"
                    >
                      <td
                        colspan="6"
                        class="font-size-16 text-uppercase py-1 font-weight-700 custom-border-bottom visit-header-hover"
                        :class="[row.header_text]"
                        :style="{ color: row.header_color }"
                      >
                        {{ row.header_text }}
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <v-hover v-slot="{ hover }" :key="index">
                      <tr
                        link
                        class="visit-listing-row"
                        :key="'visit-row' + index"
                        :class="{
                          'visit-row-hover': hover,
                          'custom-border-bottom': rowData[index + 1],
                          'row-loading': isRowLoading[index],
                        }"
                        v-on:click="
                          $router.push(
                            getDefaultRoute('visit.detail', {
                              params: { id: row.visit_id },
                            })
                          )
                        "
                      >
                        <td
                          width=""
                          :class="[row.header_text]"
                          class="font-size-14 font-weight-500"
                          :style="{ color: row.header_color }"
                        >
                          <template v-if="row.visit_barcode">
                            <div class="cursor-pointer">
                              <Barcode
                                dense
                                small
                                :barcode="row.visit_barcode"
                              ></Barcode>
                            </div>
                            <div class="mt-2 mb-1 cursor-pointer">
                              <Barcode
                                dense
                                small
                                :barcode="row.ticket_barcode"
                              ></Barcode>
                            </div>
                            <div class="my-0">
                              <v-chip
                                small
                                class="font-weight-600 custom-blue-border text-uppercase justify-center my-0 font-10"
                                color="chip-custom-blue"
                                label
                                outlined
                              >
                                {{ row.ticket_job_type_text }}
                              </v-chip>
                            </div>
                          </template>
                          <template v-else> - </template>
                        </td>
                        <td
                          :class="[row.header_text]"
                          class="font-size-14 font-weight-500"
                          style="min-width: 340px"
                        >
                          <div class="job-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis-two-line">
                              <b>Job Title: </b>{{ row.visit_title }}
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis-two-line"
                              v-if="row.property_address"
                            >
                              <span
                                content="Service Location"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon style="font-size: 18px" color="#000">
                                    mdi-home-map-marker
                                  </v-icon>
                                </b>
                                {{ row.property_address }}
                              </span>
                            </p>
                          </div>
                        </td>
                        <td
                          width=""
                          :class="[row.header_text]"
                          class="font-size-14 font-weight-500"
                        >
                          <div class="job-listing-customer">
                            <p class="m-0">
                              <v-chip
                                label
                                small
                                color="green white--text"
                                class="d-inline mx-2"
                                v-if="row.is_property_tenant"
                                >Tenant</v-chip
                              >
                            </p>
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Company Name"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon style="font-size: 16px" color="#000">
                                    mdi-bag-checked
                                  </v-icon>
                                </b>
                                {{ row.customer_company_name }}
                              </span>
                            </p>

                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Site Location Contact Person Email"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon style="font-size: 16px" color="#000">
                                    mdi-email
                                  </v-icon>
                                </b>
                                <template
                                  v-if="row && row.property_primary_email"
                                >
                                  {{ row.property_primary_email }}
                                </template>
                                <template v-else>
                                  <em class="text-muted"> no email</em>
                                </template>
                              </span>
                            </p>
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Site Location Contact Person Phone"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon style="font-size: 16px" color="#000">
                                    mdi-phone
                                  </v-icon>
                                </b>
                                <template
                                  v-if="row && row.property_primary_phone"
                                >
                                  {{ row.property_primary_phone }}
                                </template>
                                <template v-else>
                                  <em class="text-muted"> no phone</em>
                                </template>
                              </span>
                            </p>
                          </div>
                        </td>
                        <td
                          width=""
                          :class="[row.header_text]"
                          class="font-size-14 font-weight-500"
                        >
                          <div
                            class="job-listing-customer"
                            v-if="row && row.project_barcode"
                          >
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Project #"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b> Project # : </b>
                                <template v-if="row && row.project_barcode">
                                  {{ row.project_barcode }}
                                </template>
                                <template v-else>
                                  <em class="text-muted"> no name</em>
                                </template>
                              </span>
                            </p>
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Project Name"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b> Name : </b>
                                <template v-if="row && row.project_name">
                                  {{ row.project_name }}
                                </template>
                                <template v-else>
                                  <em class="text-muted"> no name</em>
                                </template>
                              </span>
                            </p>
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Project Start Date"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b> Start : </b>
                                <template v-if="row && row.project_started">
                                  {{ formatDate(row.project_started) }}
                                </template>
                                <template v-else>
                                  <em class="text-muted"> no start date</em>
                                </template>
                              </span>
                            </p>
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Project End Date"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b> End : </b>
                                <template v-if="row && row.project_deadline">
                                  {{ formatDate(row.project_deadline) }}
                                </template>
                                <template v-else>
                                  <em class="text-muted"> no end date</em>
                                </template>
                              </span>
                            </p>
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Project Milestones"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b> Milestones : </b>
                                <template v-if="row && row.project_milestones">
                                  <template
                                    v-if="
                                      row &&
                                      row.project_milestones &&
                                      row.project_milestones[0] &&
                                      row.project_milestones[0].name
                                    "
                                  >
                                    {{ row.project_milestones[0].name }}
                                  </template>
                                  <template
                                    v-if="row.project_milestones_count > 1"
                                  >
                                    <v-chip
                                      small
                                      color="blue white--text"
                                      label
                                      class="ml-2"
                                    >
                                      <span
                                        class="font-size-16 font-weight-500"
                                      >
                                        +{{
                                          row.project_milestones_count - 1
                                        }}
                                        More</span
                                      >
                                    </v-chip>
                                  </template>
                                </template>
                                <template v-else>
                                  <em class="text-muted"> no milestone</em>
                                </template>
                              </span>
                            </p>
                            <!--    <p class="m-0">
                          <span
                            class="m-0 text-truncate"
                            content="Site Location Contact Person Phone"
                            v-tippy="{
                              arrow: true,
                              arrowType: 'round',
                              animation: 'fade',
                            }"
                          >
                            <b class="font-weight-700">
                              Budget : 
                            </b>
                            <template v-if="row && row.project_budget">
                              {{ formatMoney(row.project_budget) }}
                            </template>
                            <template v-else>
                              <em class="text-muted"> no budget</em>
                            </template>
                          </span>
                        </p> -->
                          </div>
                          <em v-else class="text-muted"> no project details</em>
                        </td>
                        <td
                          width="300px"
                          :class="[row.header_text]"
                          class="font-size-14 font-weight-500"
                          :style="{ color: row.header_color }"
                        >
                          <p class="m-0 max-content-width text-truncate">
                            <span class="d-block">
                              {{ formatDateTime(row.visit_started_at) }} -
                              {{ formatTime(row.visit_finished_at) }}
                            </span>
                            <span class="d-block">
                              <v-chip
                                class="mt-2 mb-2 font-weight-600 cursor-pointer text-lowercase"
                                color="chip-custom-blue"
                                small
                                outlined
                                label
                              >
                                <template v-if="row.visit_status === 4">
                                  completed in {{ row.visit_duration_human }}
                                </template>
                                <template v-else-if="row.visit_status === 2">
                                  in-progress
                                </template>
                                <template v-else>
                                  <template v-if="row.visit_overdue === 1"
                                    >delayed by
                                    {{
                                      calculateTotalDuration(
                                        row.visit_started_at
                                      )
                                    }}</template
                                  >
                                  <template v-else-if="row.visit_today === 1"
                                    >ends in
                                    {{
                                      calculateTotalDuration(
                                        row.visit_finished_at
                                      )
                                    }}</template
                                  >
                                  <template v-else
                                    >starts in
                                    {{
                                      calculateTotalDuration(
                                        row.visit_started_at
                                      )
                                    }}</template
                                  >
                                </template>
                              </v-chip>
                            </span>
                          </p>
                        </td>
                        <td
                          width="140px"
                          class="font-size-14 font-weight-500 td-chip-small"
                        >
                          <div
                            class="m-0 max-content-width text-truncate"
                            v-if="row.actual_start_date || row.actual_end_date"
                          >
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Actual Start Date"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b> Started At : </b>
                                <template v-if="row && row.actual_start_date">
                                  <v-chip small outlined color="red" label>
                                    {{ formatDate(row.actual_start_date) }}
                                    {{
                                      formatDateStartTime(row.actual_start_date)
                                    }}
                                  </v-chip>
                                </template>
                                <template v-else>
                                  <em class="text-muted"> no started at</em>
                                </template>
                              </span>
                            </p>
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Actual End Date"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b> Ended At &nbsp;&nbsp;: </b>
                                <template v-if="row && row.actual_end_date">
                                  <v-chip small outlined color="green" label>
                                    {{ formatDate(row.actual_end_date) }}
                                    {{
                                      formatDateStartTime(row.actual_end_date)
                                    }}
                                  </v-chip>
                                </template>
                                <template v-else>
                                  <em class="text-muted"> no ended at</em>
                                </template>
                              </span>
                            </p>
                          </div>
                          <em v-else class="text-muted">
                            no actual date / time</em
                          >
                        </td>
                        <td
                          width="140px"
                          class="font-size-14 font-weight-500 td-chip-small"
                        >
                          <CustomStatus
                            small
                            :status="statusIntiger(row.visit_status)"
                            endpoint="visit/status"
                          ></CustomStatus>
                          <Priority
                            small
                            :priority="priorityIntiger(row.ticket_priority)"
                          ></Priority>
                          <!--  <template v-if="row.visit_payment_status">
                            <div class="my-1">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    label
                                    class="mx-2 d-inline justify-center font-10"
                                    :color="
                                      row.visit_payment_status == 1
                                        ? 'green white--text'
                                        : 'orange white--text'
                                    "
                                  >
                                    {{
                                      row.visit_payment_status == 1
                                        ? "PAID"
                                        : "PARTIAL PAID"
                                    }}
                                  </v-chip>
                                </template>
                                <span>Payment Status</span>
                              </v-tooltip>
                            </div>
                          </template> -->
                        </td>
                        <td width="240px" class="font-size-14 font-weight-500">
                          <div class="job-listing-customer">
                            <p
                              class="m-0 max-content-width text-truncate font-weight-700 text-capitalize"
                              :class="{
                                'text--secondary': lodash.isEmpty(
                                  row.visit_engineers
                                ),
                              }"
                            >
                              {{ engineerDisplayText(row.visit_engineers) }}
                            </p>
                          </div>

                          <v-btn
                            v-if="
                              getPermission('visit:delete') &&
                              row.visit_status == 5
                            "
                            depressed
                            color="red lighten-1 white--text visit-delete-btn my-auto"
                            v-on:click.stop="deleteVisit(row)"
                          >
                            <v-icon style="font-size: 18px; line-height: 18px"
                              >mdi-delete</v-icon
                            >
                            Delete
                          </v-btn>

                          <v-chip
                            v-if="
                              row.is_rejeced == 1 &&
                              row.visit_engineers &&
                              row.visit_engineers.length == 0
                            "
                            label
                            color="red"
                            outlined
                            text-color=""
                            class="p-3 mr-5 mb-1"
                            small
                            >Re-assignment Pending
                          </v-chip>
                          <div>
                            <v-chip
                              v-if="
                                row.visit_engineers &&
                                row.visit_engineers.length > 0
                              "
                              label
                              color="orange"
                              class="p-3 mr-5"
                              text-color="white"
                              small
                              >Assign</v-chip
                            >
                            <v-chip
                              v-if="
                                row.visit_engineers &&
                                row.visit_engineers.length == 0
                              "
                              label
                              color="#24326d"
                              class="p-3 mr-5"
                              text-color="white"
                              small
                              >Unassign</v-chip
                            >
                          </div>
                          <!-- <div class="job-listing-customer">
                            <p
                              class="m-0 max-content-width text-truncate font-weight-700"
                              :class="{
                                'text--secondary': lodash.isEmpty(
                                  row.visit_engineers
                                ),
                              }"
                            >
                              {{ engineerDisplayText(row.visit_engineers) }}
                            </p>
                          </div>
                          <template>
                            <v-btn
                              depressed
                              color="red lighten-1 white--text visit-delete-btn my-auto"
                              v-on:click.stop="deleteVisit(row)"
                            >
                              <v-icon style="font-size: 18px; line-height: 18px"
                                >mdi-delete</v-icon
                              >
                              Delete
                            </v-btn>
                          </template> -->
                        </td>
                        <td width="240px" class="font-size-14 font-weight-500">
                          <v-chip
                            v-if="row.added_by_display_name"
                            class="mx-2 my-1 custom-grey-border"
                            color="blue-grey darken-1"
                            text-color="white"
                          >
                            <v-avatar left>
                              <v-icon>mdi-account-circle</v-icon>
                            </v-avatar>
                            <p class="m-0 custom-nowrap-ellipsis">
                              {{ row.added_by_display_name }}
                            </p>
                          </v-chip>
                          <TableActivity
                            v-if="!lodash.isEmpty(row.added_by_display_name)"
                            :data="row"
                          >
                            <template v-slot:date_time>
                              {{ row.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(row.visit_added_at) }}
                              {{ formatDateStartTime(row.visit_added_at) }}
                            </template>
                          </TableActivity>
                        </td>
                      </tr>
                    </v-hover>
                  </template>
                </template>
              </template>
              <tr v-else>
                <td colspan="8" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no visit at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
    <DeleteDialog :delete-dialog="deleteVisitDialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-16 font-weight-500 delete-text">
          Once you delete
          <span class="font-weight-700">{{ deleteVisitBarcode }}</span
          >, you won't be able to retrieve this later.<br />Are you sure you
          want to delete
          <span class="font-weight-700">{{ deleteVisitBarcode }}</span>
          ?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="deleteVisitLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="deleteVisitDialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="deleteVisitLoading"
          :loading="deleteVisitLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteVisitConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
  </div>
</template>

<script>
import moment from "moment-timezone";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";

import { QUERY, DELETE } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import Priority from "@/view/pages/partials/Priority.vue";
import DeleteDialog from "@/view/pages/partials/Delete";
import { toSafeInteger } from "lodash";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "dashboard-visit",
  mixins: [CommonMixin],
  data() {
    return {
      pageLoading: false,
      internal: true,
      rowData: [],
      isRowLoading: {},
      totalPages: 0,
      currentPage: 0,
      perPage: 0,
      totalRows: 0,
      rowsOffset: 0,
      dates: [],
      entityObject: {},
      filter_search: null,
      filter_invoice: null,
      filter_quotation: null,
      filter_customer: null,
      filter_engineer: null,
      filter_status: null,
      filter_property: null,
      visitType: "overdue",
      deleteVisitId: null,
      deleteVisitDialog: false,
      deleteVisitLoading: false,
      deleteVisitBarcode: null,
    };
  },
  methods: {
    statusIntiger(param) {
      return toSafeInteger(param);
    },
    priorityIntiger(param) {
      return toSafeInteger(param);
    },
    deleteVisit({ visit_id, visit_barcode }) {
      this.deleteVisitId = visit_id;
      this.deleteVisitDialog = true;
      this.deleteVisitBarcode = visit_barcode;
    },
    deleteVisitConfirm() {
      this.deleteVisitLoading = true;
      this.$store
        .dispatch(DELETE, { url: `visit/${this.deleteVisitId}` })
        .then(() => {
          this.deleteVisitId = null;
          this.deleteVisitDialog = false;
          this.deleteVisitBarcode = null;
          this.getVisits();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.deleteVisitLoading = false;
        });
    },

    getVisitList(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "visit",
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        display_name.push(param[0].display_name);
        if (param.length - 1) {
          const message = ` +${param.length - 1} more...`;
          display_name.push(message);
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
    calculateTotalDuration(visit_date) {
      if (moment(visit_date).isValid()) {
        let a = moment(visit_date);
        let b = moment();

        let totalDuration = moment.duration(a.diff(b));
        if (b.isAfter(a)) {
          totalDuration = moment.duration(b.diff(a));
        }

        let result = [];

        let years = totalDuration.get("years");
        if (years > 0) {
          if (years == 1) {
            result.push(years + " Year");
          } else {
            result.push(years + " Years");
          }
        }

        let months = totalDuration.get("months");
        if (months > 0) {
          if (months == 1) {
            result.push(months + " Month");
          } else {
            result.push(months + " Months");
          }
        }

        let days = totalDuration.get("days");
        if (days > 0) {
          if (days == 1) {
            result.push(days + " Day");
          } else {
            result.push(days + " Days");
          }
        }

        let hours = totalDuration.get("hours");
        if (hours > 0) {
          if (hours == 1) {
            result.push(hours + " Hour");
          } else {
            result.push(hours + " Hours");
          }
        }

        let minutes = totalDuration.get("minutes");
        if (minutes > 0) {
          if (minutes == 1) {
            result.push(minutes + " Minute");
          } else {
            result.push(minutes + " Minutes");
          }
        }

        return result.join(" ");
      }
    },
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    getVisits() {
      const _this = this;

      if (_this.pageLoading) {
        return false;
      }

      let filter = {
        daterange: _this.dates,
        entity: _this.entityObject ? _this.entityObject.id : undefined,
        search: _this.lodash.toString(_this.filter_search) || null,
        invoice: _this.lodash.toSafeInteger(_this.filter_invoice) || null,
        quotation: _this.lodash.toSafeInteger(_this.filter_quotation) || null,
        customer: _this.lodash.toSafeInteger(_this.filter_customer) || null,
        engineer: _this.lodash.toSafeInteger(_this.filter_engineer) || null,
        status: _this.lodash.toSafeInteger(_this.filter_status) || null,
        property: _this.lodash.toSafeInteger(_this.filter_property) || null,
        type: _this.visitType,
      };

      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }

      _this.rowData = [];

      _this.pageLoading = true;

      let current_page = _this.currentPage;
      let per_page = _this.perPage;

      _this
        .getVisitList({ filter, current_page, per_page })
        .then((data) => {
          _this.rowData = data.rows;
          if (_this.rowData.length) {
            _this.$emit("init-component", true);
          }
          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          _this.totalRows = data.total_rows;
          _this.rowsOffset = data.offset;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.pageLoading = false;
    this.getVisits();
  },
  components: {
    Barcode,
    CustomStatus,
    Priority,
    DeleteDialog,
    TableActivity,
  },
};
</script>
